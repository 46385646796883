
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://www.app.pow21.com/api/',
  fileAccessUrl: 'https://app.pow21.com/',
  apiKey: '57289d1b6580bc64a58830e09031e745'
};
