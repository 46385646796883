export class ReviewDisp {
  title: string;
  message: string;
  rating: any;
  user_id: number;
  status: number;
  name: string;
  email: string;
  disp_id: number;
  id: any;
  //review_id:number;
  
}
