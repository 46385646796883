export class Register {
    name: string;
    email: string;
    password: string;
    cpassword: string;
    status: number = 1;
    referrer_id: string;
    is_updates: any;
    id_cms_privileges: number;
    is_terms: any = true;
    user_id: any = 0;
    
}